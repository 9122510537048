import Card from "react-bootstrap/Card";
import Markdown from "react-markdown";
import headphonesMan from "/headphones-man.png";
import time from "/time.png";
import nyTimes from "/ny-times.png";
import fastCo from "/fastco.png";
import businessInsider from "/business-insider.png";
import "./Home.css";
import { Link } from "react-router-dom";
import articles from "./homePageArticles.json";

const Hero = () => {
	return (
		<Card className="mb-3 p-3">
			<Card.Body className="d-flex flex-column flex-md-row">
				<img
					className="align-self-center me-5 me-md-0 pb-3 me-md-5"
					src={headphonesMan}
					style={{ maxWidth: "120px" }}
					alt="Man listening to Coffitivity"
				/>
				<div className="align-self-center">
					<h1 className="pb-3 text-center">Welcome to Coffitivity!</h1>
					<p>
						Coffitivity recreates the ambient sounds of a cafe to boost your
						creativity and help you work better. Proven and peer reviewed, see
						the <Link to="research">research</Link> to learn more.
					</p>
				</div>
			</Card.Body>
		</Card>
	);
};

const Articles = () => {
	return (
		<div className="d-flex flex-column">
			{articles.map((article, index) => {
				const articleClass = `${index === 0 ? "first-article" : "article"} mb-3 p-3`;

				return (
					<Card key={article.id} className={articleClass}>
						<Card.Body>
							<Card.Text>
								<a className="fs-2 pb-2 d-block" href={article.titleLink}>
									{article.title}
								</a>
								<Markdown className="article-markdown mb-4">
									{article.bodyMarkdown}
								</Markdown>
								<a href={article.ctaLink}>
									{article.ctaText ? article.ctaText : "Read More"}
								</a>
							</Card.Text>
						</Card.Body>
					</Card>
				);
			})}
		</div>
	);
};

const Press = () => {
	return (
		<Card className="mb-3">
			<Card.Body>
				<Card.Text className="pb-3">
					<p className="text-center fs-6 h5 pb-2">As Seen In</p>
					<div className="d-flex flex-column flex-md-row justify-content-evenly">
						<img
							style={{ maxHeight: "60px", objectFit: "none" }}
							src={time}
							alt="Time"
						/>
						<img
							style={{ maxHeight: "60px", objectFit: "none" }}
							src={nyTimes}
							alt="New York Times"
						/>
						<img
							style={{ maxHeight: "60px", objectFit: "none" }}
							src={fastCo}
							alt="Fast Company"
						/>
						<img
							style={{ maxHeight: "60px", objectFit: "none" }}
							src={businessInsider}
							alt="Business Insider"
						/>
					</div>
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

const Home = () => {
	return (
		<div id="home">
			<Hero />
			<Articles />
			<Press />
		</div>
	);
};

export default Home;
