import type React from "react";
import { useAudio } from "../../providers/AudioProvider";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AudioControls: React.FC = () => {
	const { handleMuteEvent, isPlaying, onNext, play, pause, setVolume, volume } =
		useAudio();

	const [volumeIcon, setVolumeIcon] = useState("volume-up");
	const navigate = useNavigate();

	const changeVolumeIcon = useCallback((volumeLevel: number) => {
		if (volumeLevel < 5) {
			return "volume-off";
		}

		if (volumeLevel < 66) {
			return "volume-down";
		}

		return "volume-up";
	}, []);

	useEffect(() => {
		setVolumeIcon(changeVolumeIcon(volume * 100));
	}, [volume, changeVolumeIcon]);

	return (
		<div
			style={{ minHeight: "100px", backgroundColor: "#00a79d" }}
			className="
				p-2
				w-100 mb-3 
				rounded-1
				d-flex 
				flex-row 
				align-items-center
			"
		>
			<Button
				variant="secondary"
				style={{ paddingTop: "8px" }}
				onClick={() => (isPlaying ? pause() : play())}
			>
				<i
					style={{ fontSize: "48px" }}
					className={`bi bi-${isPlaying ? "pause" : "play"} text-white`}
				/>
			</Button>

			<Button
				variant="secondary"
				style={{ paddingTop: "8px" }}
				onClick={() => onNext(navigate)}
			>
				<i
					style={{ fontSize: "48px" }}
					className="bi bi-fast-forward text-white"
				/>
			</Button>

			<div className="d-flex flex-grow-1 flex-row justify-content-between align-items-baseline">
				<Button
					style={{
						paddingTop: "16px",
						paddingBottom: "16px",
					}}
					variant="secondary"
					onClick={handleMuteEvent}
				>
					<i
						style={{ fontSize: "36px" }}
						className={`bi bi-${volumeIcon} text-white`}
					/>
				</Button>
				<Form.Range
					className="px-3 align-self-center"
					value={volume * 100}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setVolume(Number(e.target.value))
					}
				/>
			</div>
		</div>
	);
};

export default AudioControls;
