import Card from "react-bootstrap/esm/Card";
import Button from "react-bootstrap/esm/Button";
import Alert from "react-bootstrap/esm/Alert";
import Form from "react-bootstrap/esm/Form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import contact from "../services/contact";
import { Turnstile } from "@marsidev/react-turnstile";

// Define the schema using Zod without the file input
const contactDataSchema = z.object({
	name: z.string().min(2, "Name must be at least 2 characters"),
	email: z.string().email("Invalid email address"),
	challengeToken: z.string(),
	issueType: z.enum(["Bug", "Feature Request", "General Feedback"]),
	description: z.string().min(10, "Description must be at least 10 characters"),
	priority: z.enum(["Low", "Medium", "High"]),
});

// TypeScript type from Zod schema
type ContactFormData = z.infer<typeof contactDataSchema>;

export const Contact = () => {
	const turnstileKey = import.meta.env.VITE_TURNSTILE_KEY;
	const { user } = useAuth();
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [challenge, setChallenge] = useState(false);
	const [challengeToken, setChallengeToken] = useState("");

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<ContactFormData>({
		resolver: zodResolver(contactDataSchema),
		defaultValues: {
			name: "",
			email: user?.email || "",
			issueType: "Bug",
			description: "",
			priority: "Medium",
			challengeToken: "",
		},
	});

	const onChallengeSuccess = (token: string) => {
		// Handle the challenge success
		setChallenge(true);
		setChallengeToken(token);
	};

	const onSubmit = async (data: ContactFormData) => {
		if (challenge) {
			try {
				data.challengeToken = challengeToken;
				await contact.newSubmission(data);
				setSuccess(true);

				// Perform submission logic, e.g., sending to an API
				// Once done, reset the form
				reset();
			} catch (error) {
				setError(true);
			}
		}
	};

	return (
		<Card>
			<Card.Header>
				<Card.Title>Contact & Support</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Text>
					<p>
						Did we do something ridiculous? Is something not working the way it
						should? Leave us a note and we'll hop on fixing the problem. Be as
						detailed as possible so we can get back to you with the best info
						for your issue!
					</p>
					{success && (
						<Alert
							variant="success"
							onClose={() => setSuccess(false)}
							dismissible
						>
							<Alert.Heading>Issue Submitted!</Alert.Heading>
							<p>Your issue has been submitted successfully!</p>
						</Alert>
					)}
					{error && (
						<Alert variant="danger" onClose={() => setError(false)} dismissible>
							<Alert.Heading>Unable to submit your request.</Alert.Heading>
							<p>
								Oh no! We were unable to submit your request. We're sorry for
								your inconvenience. You can email us directly at
								admin@coffitivity.com.
							</p>
						</Alert>
					)}
					<Form noValidate onSubmit={handleSubmit(onSubmit)}>
						{/* Name Field */}
						<Form.Group className="mb-3" controlId="formName">
							<Form.Label>Name</Form.Label>
							<Form.Control
								{...register("name")}
								isInvalid={!!errors.name}
								type="text"
								placeholder="Enter your name"
							/>
							<Form.Control.Feedback type="invalid">
								{errors.name?.message}
							</Form.Control.Feedback>
						</Form.Group>

						{/* Email Field */}
						<Form.Group className="mb-3" controlId="formEmail">
							<Form.Label>Email</Form.Label>

							<Form.Control
								{...register("email")}
								isInvalid={!!errors.email}
								type="email"
								placeholder="Enter your email"
							/>
							<Form.Control.Feedback type="invalid">
								{errors.email?.message}
							</Form.Control.Feedback>
						</Form.Group>

						{/* Issue Type Field */}
						<Form.Group className="mb-3" controlId="formIssueType">
							<Form.Label>Issue Type</Form.Label>
							<Form.Select
								{...register("issueType")}
								isInvalid={!!errors.issueType}
							>
								<option value="Bug">Bug</option>
								<option value="Feature Request">Feature Request</option>
								<option value="General Feedback">General Feedback</option>
							</Form.Select>
							<Form.Control.Feedback type="invalid">
								{errors.issueType?.message}
							</Form.Control.Feedback>
						</Form.Group>

						{/* Priority Field */}
						<Form.Group className="mb-3" controlId="formPriority">
							<Form.Label>Priority</Form.Label>
							<Form.Select
								{...register("priority")}
								isInvalid={!!errors.priority}
							>
								<option value="Low">Low</option>
								<option value="Medium">Medium</option>
								<option value="High">High</option>
							</Form.Select>
							<Form.Control.Feedback type="invalid">
								{errors.priority?.message}
							</Form.Control.Feedback>
						</Form.Group>

						{/* Description Field */}
						<Form.Group className="mb-3" controlId="formDescription">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								{...register("description")}
								isInvalid={!!errors.description}
								placeholder="Describe the issue in detail..."
							/>
							<Form.Control.Feedback type="invalid">
								{errors.description?.message}
							</Form.Control.Feedback>
						</Form.Group>

						<Turnstile
							siteKey={turnstileKey}
							className="mb-3"
							options={{ theme: "light" }}
							onSuccess={(t) => onChallengeSuccess(t)}
						/>

						{/* Submit Button */}
						<Button type="submit" variant="primary" disabled={!challenge}>
							Submit
						</Button>
					</Form>
				</Card.Text>
			</Card.Body>
		</Card>
	);
};
