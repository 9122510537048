import type { ContactRouterType } from "@coffitivity/user-api/src/routes/contact"; // Import ContactRouter type
import { hc } from "hono/client";
import type { InferRequestType } from "hono/client";
import ErrorHandler from "../ErrorHandler";

// Initialize the RPC client for the contact router
const client = hc<ContactRouterType>(
	`${import.meta.env.VITE_USER_SERVER_URL}/contact`,
);

type ContactSubmissionArgs = InferRequestType<
	typeof client.index.$post
>["json"];

// Define the contact API abstraction
const contact = {
	// Method to send a new contact submission
	newSubmission: async (data: ContactSubmissionArgs) => {
		try {
			// Make a POST request with the contact submission data
			const response = await client.index.$post({
				json: data, // Send data as JSON in the request body
			});

			// Ensure the request was successful
			if (response.ok) {
				const responseData = await response.json();
				return Promise.resolve(responseData);
			}

			return Promise.reject(new Error("Failed to submit contact"));
		} catch (error) {
			// Catch and log any errors
			ErrorHandler.captureException(error);
			return Promise.reject(new Error("Failed to submit contact"));
		}
	},
};

export default contact;
