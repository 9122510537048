const ErrorPage = () => {
	return (
		<div>
			<h1>Error</h1>
			<p>Something went wrong.</p>
		</div>
	);
};

export default ErrorPage;
