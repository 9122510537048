import { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import "./signIn.css"; // Assume you have this file for custom styles
import { Link, useNavigate } from "react-router-dom";
import letterMark from "/letter-mark.png";
import authService from "../../services/auth";
import Alert from "react-bootstrap/esm/Alert";

const ForgotPassword: React.FC = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	// handle form submit
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		setError(""); // clear any previous errors
		event.preventDefault(); // prevent the form from refreshing the page

		try {
			if (!email) {
				setError("Please enter an email address.");
				return;
			}

			// make a post request to your server
			const [success, error] = await authService.forgotPassword(email);

			if (error) {
				setError(error.message);
				return;
			}

			// if successful, save the tokens, redirect to the home page
			if (success) {
				setSuccess("Please check your email to verify your account.");
				setEmail("");
			}
		} catch (err) {
			// TODO: log to sentry
			console.error("Error:", err);
			setError("We apologize, but an error occurred. Please try again later.");
		}
	};

	return (
		<Container fluid className="h-100">
			<Row className="justify-content-center align-items-center h-100">
				<Col xs={12} md={6} xl={4}>
					<Link to="/">
						<img
							src={letterMark}
							alt="Coffitivity"
							className="d-block mx-auto mb-3"
						/>
					</Link>
					{error && <Alert variant="danger">{error}</Alert>}
					{success && <Alert variant="success">{success}</Alert>}
					<Card>
						<Card.Header>
							<Card.Title className="d-flex justify-content-between">
								<span>Forgot Password</span>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleSubmit}>
								<p className="text-body-secondary">
									Enter your email address below and we'll send an email code to
									reset your password.
								</p>
								<Form.Group className="mb-3" controlId="signInEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</Form.Group>

								<div className="d-flex flex-row-reverse justify-content-between mt-3">
									<Button variant="primary" type="submit">
										Submit
									</Button>

									<Button variant="warning" onClick={() => navigate(-1)}>
										Back
									</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ForgotPassword;
