import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import "./signIn.css"; // Assume you have this file for custom styles
import { Link, useNavigate } from "react-router-dom";
import letterMark from "/letter-mark.png";
import authService from "../../services/auth";
import Alert from "react-bootstrap/esm/Alert";

const SignUp: React.FC = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [newsletter, setNewsletter] = useState(""); // this value is either "on" or ""
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	useEffect(() => {
		if (password !== passwordConfirmation) {
			setPasswordError("Passwords do not match");
		} else {
			setPasswordError("");
		}
	}, [password, passwordConfirmation]);

	// handle form submit
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		setError(""); // clear any previous errors
		event.preventDefault(); // prevent the form from refreshing the page

		try {
			if (!email) {
				setError("Please enter an email address.");
				return;
			}

			if (!password) {
				setError("Please enter a password.");
				return;
			}

			if (!passwordConfirmation) {
				setError("Please confirm your password.");
				return;
			}

			// make a post request to your server
			const [success, error] = await authService.signUp(
				email,
				password,
				newsletter,
			);

			if (error) {
				setError(error.message);
				return;
			}

			// if successful, save the tokens, redirect to the home page
			if (success) {
				setSuccess("Please check your email to verify your account.");
				setEmail("");
				setPassword("");
				setPasswordConfirmation("");
				setNewsletter("");
			}
		} catch (err) {
			// TODO: log to sentry
			console.error("Error:", err);
			setError("We apologize, but an error occurred. Please try again later.");
		}
	};

	return (
		<Container fluid className="h-100">
			<Row className="justify-content-center align-items-center h-100">
				<Col xs={12} md={6} xl={4}>
					<Link to="/">
						<img
							src={letterMark}
							alt="Coffitivity"
							className="d-block mx-auto mb-3"
						/>
					</Link>
					{error && (
						<Alert variant="danger" role="alert">
							{error}
						</Alert>
					)}
					{success && (
						<Alert variant="success" role="alert">
							{success}
						</Alert>
					)}
					<Card>
						<Card.Header>
							<Card.Title className="d-flex justify-content-between">
								<span>Create An Account</span>
								<Link to="/sign-in" className="btn btn-link">
									Sign In
								</Link>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="signUpEmail">
									<Form.Label>Your Email Address</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="password">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="passwordConfirmation">
									<Form.Label>Password Confirmation</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										isInvalid={!!passwordError}
										onChange={(e) => setPasswordConfirmation(e.target.value)}
										value={passwordConfirmation}
									/>
								</Form.Group>

								<Form.Group controlId="newsletterSignUp">
									<Form.Check
										type="checkbox"
										id="newsletter"
										label="Would like to join our news letter and stay current with new features and promotions from Coffitivity?"
										onChange={(e) => setNewsletter(e.target.value)}
									/>
								</Form.Group>

								{passwordError && (
									<Form.Text className="text-danger">{passwordError}</Form.Text>
								)}

								<div className="d-flex flex-row-reverse justify-content-between mt-3">
									<Button variant="primary" type="submit">
										Create
									</Button>

									<Button variant="warning" onClick={() => navigate(-1)}>
										Back
									</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
					<p className="mt-4 text-center">
						By clicking create, you agree to our{" "}
						<Link to="/terms">Terms of Service and Privacy Policy</Link>.
					</p>
				</Col>
			</Row>
		</Container>
	);
};

export default SignUp;
