import Card from "react-bootstrap/esm/Card"

export const About = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title>About Us</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Text>
					When we started Coffitivity, it was very much a side project to build a tool to help us work better. We're a team of entrepreneurs, freelancers, and creatives in Richmond, Virginia, and we all LOVE the vibe of the coffee shop.
					<br />
					<br />
					Today, our goal at Coffitivity is simple: we're here to help you work better. We started with a way to boost your creativity and now we're working on a suite of tools to give you the extra kick in other aspects of your work day.
				</Card.Text>
			</Card.Body>
		</Card>
	)
}