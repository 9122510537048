import type React from "react";
import { useEffect, useRef } from "react";
import { useAudio } from "../../providers/AudioProvider";

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/crossorigin MDN}
 */
type CrossOrigin = "anonymous" | "use-credentials" | "" | undefined;

interface AudioElementProps {
	autoPlay?: boolean;
	children?: React.ReactNode;
	className?: string;
	controls?: boolean;
	controlsList?: string;
	crossOrigin?: CrossOrigin;
	id?: string;
	listenInterval?: number;
	loop?: boolean;
	muted?: boolean;
	onAbort?: (e: Event) => void;
	onCanPlay?: (e: Event) => void;
	onCanPlayThrough?: (e: Event) => void;
	onEnded?: (e: Event) => void;
	onError?: (e: Event) => void;
	onListen?: (time: number) => void;
	onLoadedMetadata?: (e: Event) => void;
	onPause?: (e: Event) => void;
	onPlay?: (e: Event) => void;
	onSeeked?: (e: Event) => void;
	onVolumeChanged?: (e: Event) => void;
	preload?: "" | "none" | "metadata" | "auto";
	style?: React.CSSProperties;
	title?: string;
	volume?: number;
}

const AudioElement: React.FC<AudioElementProps> = ({
	autoPlay = true,
	children = null,
	className = "",
	controls = true,
	controlsList,
	crossOrigin,
	id,
	listenInterval = 10000,
	loop = true,
	muted = false,
	onAbort = () => {},
	onCanPlay = () => {},
	onCanPlayThrough = () => {},
	onEnded = () => {},
	onError = () => {},
	onListen = () => {},
	onSeeked = () => {},
	onVolumeChanged = () => {},
	onLoadedMetadata = () => {},
	preload = "metadata",
	style = {},
	title,
}) => {
	const { audioRef, isUserEngaged, volume, currentTrack, onPause, onPlay } =
		useAudio();
	const listenTracker = useRef<number | undefined>(undefined);

	useEffect(() => {
		const audio = audioRef.current;

		const clearListenTrack = () => {
			if (listenTracker.current) {
				clearInterval(listenTracker.current);
				listenTracker.current = undefined;
			}
		};

		const setListenTrack = () => {
			if (!listenTracker.current) {
				listenTracker.current = window.setInterval(() => {
					if (audioRef.current) {
						onListen(audioRef.current.currentTime);
					}
				}, listenInterval);
			}
		};

		const updateVolume = (volume: number) => {
			if (audio && typeof volume === "number" && volume !== audio.volume) {
				audio.volume = volume;
			}
		};

		if (!audio) return;

		updateVolume(volume);

		const handleEvents = (type: string, handler: EventListener) => {
			audio.addEventListener(type, handler);
			return () => audio.removeEventListener(type, handler);
		};

		const handlers = [
			handleEvents("error", onError),
			handleEvents("canplay", onCanPlay),
			handleEvents("canplaythrough", onCanPlayThrough),
			handleEvents("play", () => {
				setListenTrack();
				onPlay();
			}),
			handleEvents("abort", (e) => {
				clearListenTrack();
				onAbort(e);
			}),
			handleEvents("ended", (e) => {
				clearListenTrack();
				onEnded(e);
			}),
			handleEvents("pause", () => {
				clearListenTrack();
				onPause();
			}),
			handleEvents("seeked", onSeeked),
			handleEvents("loadedmetadata", onLoadedMetadata),
			handleEvents("volumechange", onVolumeChanged),
		];

		return () => {
			for (const handler of handlers) {
				handler(); // this unsubscribes the event listener
			}
		};
	}, [
		volume,
		listenInterval,
		onListen,
		onError,
		onCanPlay,
		onCanPlayThrough,
		onPlay,
		onAbort,
		onEnded,
		onPause,
		onSeeked,
		onLoadedMetadata,
		onVolumeChanged,
		audioRef.current,
	]);

	useEffect(() => {
		const audio = audioRef.current;
		if (audio) {
			audio.volume = volume;
		}
	}, [volume, audioRef.current]);

	const compatibilityMessage = children || (
		<p>
			Your browser does not support the <code>audio</code> element.
		</p>
	);

	const displayTitle = title ? title : currentTrack.title;
	const conditionalProps = controlsList ? { controlsList } : {};

	return (
		<audio
			ref={audioRef}
			autoPlay={isUserEngaged && autoPlay}
			className={`audio-player ${className}`}
			controls={controls}
			crossOrigin={crossOrigin}
			id={id}
			loop={loop}
			muted={muted}
			preload={preload}
			src={currentTrack.location}
			style={style}
			title={displayTitle}
			{...conditionalProps}
		>
			{compatibilityMessage}
		</audio>
	);
};

export default AudioElement;
