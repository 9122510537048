import { Outlet } from "react-router-dom";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer";
import MainNav from "./components/MainNav";
import { TermsOfService } from "./components/TermsOfService";
import { YouTubeLink } from "./components/YouTubeLink";
import Sentry from "./ErrorHandler";

function App() {
	return (
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
			<MainNav />
			<div
				style={{ width: "100%", maxWidth: "1200px" }}
				className="mx-auto px-3 px-xxl-0 d-flex flex-column flex-lg-row"
			>
				<div className="side-bar me-lg-3">
					<AudioPlayer />
					<YouTubeLink className="d-none d-lg-block" />
					<TermsOfService className="d-none d-lg-block" />
				</div>

				<div className="flex-lg-grow-1">
					<Outlet />
					<YouTubeLink className="d-lg-none" />
					<TermsOfService className="d-lg-none" />
				</div>
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default App;
