import * as Sentry from "@sentry/react";

type RequestResponse<T> = [T | null, Error | null];

export const payment = {
	createCheckoutSession: async ({
		priceId,
		email,
	}: {
		priceId: string;
		email: string;
	}): Promise<RequestResponse<{ sessionUrl: string }>> => {
		const payload = {
			priceId,
			email,
		};

		try {
			const response = await fetch(
				`${import.meta.env.VITE_USER_SERVER_URL}/payment/create-checkout-session`,
				{
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
					body: JSON.stringify(payload),
					credentials: "include",
				},
			);

			const json: { data: { sessionUrl: string }; message: string } =
				await response.json();

			if (response.ok) {
				return [json.data, null];
			}

			return [null, new Error(json.message)];
		} catch (err) {
			console.error(`Error: ${err}`);
			Sentry.captureException(err);
			return [null, err as Error];
		}
	},
};

export default payment;
