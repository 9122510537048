import { Card } from "react-bootstrap"

export const Terms = () => {
	return (
		<Card className="mb-3">
			<Card.Body>
				<h2>Terms of Service</h2>
				<p>
					Coffitivity Trademarks, Credits, Terms of Use & Privacy Policy
					TRADEMARKS

					"Coffitivity", "Coffitivity.com", and "Enough Noise to Work" are trademarks of Coffitivity, LLC
					TEAM CREDITS

					Justin K. - Web Design & Development
					Joey Figaro - Web Development
					Nicole H. - Art & Design
					ACe C. - Copy & Creative Direction

					TERMS OF USE
					PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS SITE

					This web site is provided by Coffitivity, LLC (Coffitivity) and may be used for informational and entertainment purposes only. By using the site or downloading materials from the site, you agree to abide by the terms and conditions set forth in this notice. If you do not agree to abide by these terms and conditions, do not use the site or download materials from the site.
					LIMITED LICENSE

					Subject to the terms and conditions set forth in this Agreement, Coffitivity grants you a non-exclusive, non-transferable, limited right to access, use and display this site and the materials thereon. You agree not to interrupt or attempt to interrupt the operation of the site in any way.

					Coffitivity authorizes you to view and download the information ("Materials") at this web site ("Site") only for your personal, non-commercial use. This authorization is not a transfer of title in the Materials and copies of the Materials and is subject to the following restrictions: 1) you must retain, on all copies of the Materials downloaded, all copyright and other proprietary notices contained in the Materials; 2) you may not modify the Materials in any way or reproduce or publicly display, perform, or distribute or otherwise use them for any public or commercial purpose; and 3) you must not transfer the Materials to any other person unless you give them notice of, and they agree to accept, the obligations arising under these terms and conditions of use. You agree to abide by all additional restrictions displayed on the Site as it may be updated from time to time. This Site, including all Materials, is copyrighted and protected by worldwide copyright laws and treaty provisions. You agree to comply with all copyright laws worldwide in your use of this Site and to prevent any unauthorized copying of the Materials. Except as expressly provided herein, Coffitivity does not grant any express or implied right to you under any patents, trademarks, copyrights or trade secret information.
					DISCLAIMER

					THE MATERIALS MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. Coffitivity DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU ACKNOWLEDGE THAT ANY RELIANCE ON ANY SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION SHALL BE AT YOUR SOLE RISK. Coffitivity RESERVES THE RIGHT, IN ITS SOLE DISCRETION, TO CORRECT ANY ERRORS OR OMISSIONS IN ANY PORTION OF THE SITE. Coffitivity MAY MAKE ANY OTHER CHANGES TO THE SITE, THE MATERIALS AND THE PRODUCTS, PROGRAMS, SERVICES OR PRICES (IF ANY) DESCRIBED IN THE SITE AT ANY TIME WITHOUT NOTICE.

					THIS SITE, THE INFORMATION AND MATERIALS ON THE SITE, AND THE SOFTWARE MADE AVAILABLE ON THE SITE, ARE PROVIDED "AS IS" WITHOUT ANY REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
					THIRD PARTY SITES

					As a convenience to you, Coffitivity may provide, on this Site, links to web sites operated by other entities. If you use these sites, you will leave this Site. If you decide to visit any linked site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements. Coffitivity makes no warranty or representation regarding, and does not endorse, any linked web sites or the information appearing thereon or any of the products or services described thereon. Links do not imply that Coffitivity or this Site sponsors, endorses, is affiliated or associated with, or is legally authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked site is authorized to use any trademark, trade name, logo or copyright symbol of Coffitivity or any of its affiliates or subsidiaries.
					EXTERNAL LINKS TO THE SITE

					All links to the Site must be approved in writing by Coffitivity, except that Coffitivity consents to links in which: (i) the link, when activated by a user, displays that page full-screen in a fully operable and navigable browser window and not within a "frame" on the linked website; and (ii) the appearance, position, and other aspects of the link may neither create the false appearance that an entity or its activities or products are associated with or sponsored by Coffitivity nor be such as to damage or dilute the goodwill associated with the name and trademarks of Coffitivity or its Affiliates. Coffitivity reserves the right to revoke this consent to link at any time in its sole discretion.
					INFORMATION PROVIDED BY YOU

					Any personally identifiable information you may provide to Coffitivity via this Site is protected by the Privacy Policy (below) associated with this Site. Coffitivity does not want you to, and you should not, send any confidential or proprietary information to Coffitivity via the Site. You agree that any information or materials that you or individuals acting on your behalf provide to Coffitivity will not be considered confidential or proprietary. By providing any such information or materials to Coffitivity, you grant to Coffitivity an unrestricted, irrevocable, worldwide, royalty-free license to use, reproduce, display, publicly perform, transmit and distribute such information and materials, and you further agree that Coffitivity is free to use any ideas, concepts or know-how that you or individuals acting on your behalf provide to Coffitivity. You further recognize that Coffitivity does not want you to, and you warrant that you shall not, provide any information or materials to Coffitivity that is defamatory, threatening, obscene, harassing, or otherwise unlawful, or that incorporates the proprietary material of another.
					LIMITATIONS OF DAMAGES

					IN NO EVENT SHALL Coffitivity OR ANY OF ITS SUBSIDIARIES BE LIABLE TO ANY ENTITY FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION OR PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM) THAT ARE RELATED TO THE USE OF, OR THE INABILITY TO USE, THE CONTENT, MATERIALS, AND FUNCTIONS OF THE SITE OR ANY LINKED WEBSITE, EVEN IF Coffitivity IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
					CHANGES

					Coffitivity reserves the right, at its sole discretion, to change, modify, add or remove any portion of this Agreement in whole or in part, at any time. Changes in this Agreement will be effective when notice of such change is posted. Your continued use of the Site after any changes to this Agreement are posted will be considered acceptance of those changes.

					Coffitivity may terminate, change, suspend or discontinue any aspect of the Coffitivity Site, including the availability of any features of the Site, at any time. Coffitivity may also impose limits on certain features and services or restrict your access to parts or all of the Site without notice or liability. Coffitivity may terminate the authorization, rights and license given above and, upon such termination, you shall immediately destroy all Materials.
					INTERNATIONAL USERS AND CHOICE OF LAW

					This Site is controlled, operated and administered by Coffitivity. Coffitivity makes no representation that materials at this site are appropriate or available for use at other locations outside of the Canada and access to them from territories where their contents are illegal is prohibited. You may not use the Site or export the Materials in violation of Canadian export laws and regulations. If you access this Site from a locations outside of Canada, you are responsible for compliance with all local laws. These Terms and Conditions of use shall be governed by the laws of the Province of Alberta, without giving effect to its conflict of laws provisions.

					This Agreement constitutes the entire agreement between Coffitivity and you with respect to your use of the Site. Any cause of action you may have with respect to your use of the Site must be commenced within one (1) year after the claim or cause of action arises. If for any reason a court of competent jurisdiction finds any provision of the Agreement or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the Agreement, and the remainder of this Agreement shall continue in full force and effect.
					RETURN/REFUND POLICY

					Coffitivity does not offer any returns or refunds for subscriptions or products purchased through the Site. Any third party disputes should be settled with that specific vendor. Coffitivity reserves the right to modify, change, add or remove the price for subcriptions or other products through the Site. For disputes with THE COMPANY, please visit the Support page on the Site.
					PRIVACY POLICY

					Coffitivity, LLC (Coffitivity) is committed to protecting any personal information that you may provide to us. In particular, we believe it is important for you to know how we treat information about you that we may receive from this web site.
					USER DATA COLLECTED

					Coffitivity believes in limited collection of data. The site currently only collects email addresses as supplied by users either explicilty through sign up or through FACEBOOK SSO.
					PAYMENT DATA COLLECTED

					Coffitivity relies on a partner payment system through STRIPE to handle and store payment informaiton. No user payment information or data is stored on any Coffitivity owned system or server.
					REQUEST FOR REMOVAL

					If you would like your personal data to be removed from our servers you can request an account deletion by emailing support at team@coffitivity.com.
					INFORMATION COLLECTED

					As a visitor to our site, you can browse our pages without having any personally identifiable information revealed about you. Information we automatically collect via industry standard web log files and other tools includes the URL from which you visited, the page you are visiting, your computer browser information and your IP address. We may also track the effectiveness of various promotions through the use of data collection devices known as "cookies." Cookies contain specific pieces of information on your computer hard drive, and can be set to store information during a visit or for a longer period of time. Our cookies are not tied to personally identifiable information. Third party vendors, including Google, show our ads on sites on the internet. Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to our website. Users may opt out of Google's use of cookies by visiting the Google advertising opt-out page.

					In addition, there are portions of this web site where we may need to collect personal information from you for a specific purpose, such as to provide you with certain information you request. The information collected from you may include your e-mail address.

					This web site is not intended for persons under 13 years of age. We do not knowingly solicit or collect personal information from or about children, and we do not knowingly market our products or services to children.

					Except as stated above, we do not share, sell, or lease personal information about you to any third-parties.

					It is possible that our business may be merged with or sold to another business entity. During such a transaction, business information is typically one of the assets shared in the combined entity. In the event that this occurs, your personal information will be protected by the Coffitivity privacy policy in effect at the time.

					As a good corporate citizen, Coffitivity is interested in protecting the community at large. We will release personal information to legal authorities when we believe it is appropriate to comply with the law. In addition, Coffitivity may choose to release information about you to relevant parties in relation to investigations of fraud, piracy or other unlawful activity.
					LINKS TO OTHER SITES

					Coffitivity's web site may contain links to other sites such as Coffitivity distributors and sales affiliates. While we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content, security, or privacy practices employed by other sites.
					SECURITY OF COLLECTED INFORMATION

					We maintain strict physical, electronic, and administrative safeguards to protect your personal information from unauthorized or inappropriate access. We restrict access to information about you to those Coffitivity workers who need to know the information to respond to your inquiry or request. Workers who misuse personal information are subject to disciplinary action.
					CHANGES TO THIS POLICY

					Please check this privacy policy periodically to inform yourself of any changes. We reserve the right to modify or supplement this privacy policy at any time.
					Last updated: September 17, 2021
				</p>
			</Card.Body>
		</Card>
	)
}