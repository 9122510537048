import { Card } from "react-bootstrap";
import youtubeLogo from "/youtube-logo.svg";

export const YouTubeLink = ({ className }: { className?: string }) => {
	const classes = `${className} mb-1`;

	return (
		<Card className={classes}>
			<Card.Text className="text-center p-3 pb-4">
				<p className="fs-6 h5 pb-2">Also Stream From</p>
				<a
					href="https://www.youtube.com/@CoffitivityOfficial"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src={youtubeLogo}
						style={{ width: "175px" }}
						alt="Go To YouTube"
					/>
				</a>
			</Card.Text>
		</Card>
	);
};
