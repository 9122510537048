import type React from "react";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useAuth } from "./AuthProvider";
import type { NavigateFunction } from "react-router-dom";

export interface Track {
	id: string;
	title: string;
	description: string;
	isPremium: boolean;
	location: string;
}

interface AudioContextProps {
	audioRef: React.RefObject<HTMLAudioElement>;
	currentTrack: Track;
	handleMuteEvent: () => void;
	isPlaying: boolean;
	isUserEngaged: boolean;
	onNext: (navigate: NavigateFunction) => void;
	onPause: () => void;
	onPlay: () => void;
	pause: () => void;
	play: () => void;
	selectTrack(track: Track, navigate: NavigateFunction): void;
	setVolume: (volume: number) => void;
	tracks: Track[];
	volume: number;
}

const tracks: Track[] = [
	{
		id: "morning-murmur",
		title: "Morning Murmur",
		description: "A gentle hum gets the day going",
		isPremium: false,
		location: `${import.meta.env.VITE_AUDIO_TRACKS_URL}/morningMurmur.mp3`,
	},
	{
		id: "lunchtime-lounge",
		title: "Lunchtime Lounge",
		description: "Bustling chatter of the lunchtime rush",
		isPremium: false,
		location: `${import.meta.env.VITE_AUDIO_TRACKS_URL}/lunchtimeLounge_mp3.mp3`,
	},
	{
		id: "university-undertones",
		title: "University Undertones",
		description: "The scholarly sounds of a university cafe",
		isPremium: false,
		location: `${import.meta.env.VITE_AUDIO_TRACKS_URL}/universityUndertones_mp3.mp3`,
	},
	{
		id: "paris-paradise",
		title: "Paris Paradise",
		description: "Energizing ambiance from The City of Light",
		isPremium: true,
		location: `${import.meta.env.VITE_AUDIO_TRACKS_URL}/cafeBastille_paris_mp3.mp3`,
	},
	{
		id: "brazil-bistro",
		title: "Brazil Bistro",
		description: "The musical chatter of a Brazilian coffeehouse",
		isPremium: true,
		location: `${import.meta.env.VITE_AUDIO_TRACKS_URL}/saoPaulo_brazil_mp3.mp3`,
	},
	{
		id: "texas-teahouse",
		title: "Texas Teahouse",
		description: "Hefty sounds from a big state",
		isPremium: true,
		location: `${import.meta.env.VITE_AUDIO_TRACKS_URL}/cafeDeBrazil_mp3.mp3`,
	},
];

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const useAudio = () => {
	const context = useContext(AudioContext);
	if (!context) {
		throw new Error("useAudio must be used within an AudioProvider");
	}
	return context;
};

const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const audioRef = useRef<HTMLAudioElement>(null);
	// set the default volume to 50%
	const [volume, setVolumeState] = useState(0.75);
	const [previousVolume, setPreviousVolume] = useState(0.75);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTrack, setCurrentTrack] = useState(tracks[0]);
	const { isAuthenticated, isSubscribed } = useAuth();
	const [isUserEngaged, setIsUserEngaged] = useState(false);

	const handleKeyboardEvents = useCallback(
		(event: KeyboardEvent) => {
			// Get the target element where the key was pressed
			const target = event.target as HTMLElement;

			// Check if the target is a form element where space key should be allowed
			const isTypingElement = ["INPUT", "TEXTAREA", "SELECT"].includes(
				target.tagName,
			);

			// If in a form control, do not trigger play/pause
			if (isTypingElement) return;

			// Handle space key to play/pause the audio if not typing
			if (event.key === " " || event.code === "Space") {
				event.preventDefault();
				if (isPlaying) {
					pause();
				} else {
					play();
				}
			}
		},
		[isPlaying], // Make sure you define these dependencies
	);

	const onNext = (navigate: NavigateFunction) => {
		const currentIndex = tracks.findIndex(
			(track) => track.id === currentTrack.id,
		);
		const nextIndex = (currentIndex + 1) % tracks.length;
		selectTrack(tracks[nextIndex], navigate);
	};

	const onPlay = () => {
		setIsPlaying(true);
	};

	const onPause = () => {
		setIsPlaying(false);
	};

	const pause = () => {
		if (audioRef.current) {
			audioRef.current.pause();
		}
	};

	// Function to play the audio
	const play = () => {
		if (audioRef.current) {
			audioRef.current.play();
		}

		if (!isUserEngaged) {
			setIsUserEngaged(true);
		}
	};

	// I'm dependency injecting navigate here because the audio provider is outside of the router (might be better to make the router the highest provider component)
	const selectTrack = (track: Track, navigate: NavigateFunction) => {
		if (!isUserEngaged) {
			setIsUserEngaged(true);
		}

		if (track.isPremium) {
			if (isSubscribed && isAuthenticated) {
				// reset the track in state
				setCurrentTrack(track);
				// tell the audio element to load the new track
				audioRef.current?.load();
			} else {
				return navigate("/premium");
			}
		} else {
			// reset the track in state
			setCurrentTrack(track);
		}
		// tell the audio element to load the new track
		audioRef.current?.load();
	};

	const setVolume = (volume: number) => {
		// catch just to confirm that the volume is being set correctly for the audio element which expects a value between 0 and 1
		if (volume > 1) {
			return setVolumeState(volume / 100);
		}

		return setVolumeState(volume);
	};

	const handleMuteEvent = () => {
		if (volume > 0) {
			setPreviousVolume(volume);
			setVolume(0);
		} else {
			setVolume(previousVolume);
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyboardEvents);
		return () => {
			window.removeEventListener("keydown", handleKeyboardEvents);
		};
	}, [handleKeyboardEvents]);

	return (
		<AudioContext.Provider
			value={{
				audioRef,
				currentTrack,
				handleMuteEvent,
				isPlaying,
				isUserEngaged,
				onNext,
				onPause,
				onPlay,
				pause,
				play,
				selectTrack,
				setVolume,
				tracks,
				volume,
			}}
		>
			{children}
		</AudioContext.Provider>
	);
};

export default AudioProvider;
