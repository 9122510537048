import ListGroup from "react-bootstrap/ListGroup";
import { type Track, useAudio } from "../../providers/AudioProvider";
import "./AudioTracks.css";
import { type NavigateFunction, useNavigate } from "react-router-dom";

const TrackHeader = () => (
	<ListGroup.Item
		style={{
			backgroundColor: "#00a79d",
			borderColor: "#00a79d",
			color: "#fff",
			textTransform: "uppercase",
		}}
	>
		Cafe Library
	</ListGroup.Item>
);

const AudioTrack = ({
	track,
	currentTrack,
	selectTrack,
}: {
	track: Track;
	currentTrack: Track;
	selectTrack: (track: Track, navigate: NavigateFunction) => void;
}) => {
	const isSelected = currentTrack.id === track.id;
	const navigate = useNavigate();

	return (
		<ListGroup.Item
			className="audio-track-item"
			onClick={() => selectTrack(track, navigate)}
			style={{
				padding: 0,
			}}
		>
			<div className="d-flex align-items-center flex-row justify-content-between">
				<div className="d-flex align-items-center flex-row">
					<div
						style={{
							height: 60,
							minWidth: 60,
							backgroundColor: "#f2f2f2",
							color: isSelected ? "#555" : "#bfbfbf",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<i className="bi bi-music-note-beamed me-2 fs-3 ps-1 pt-1" />
					</div>
					<div className="d-flex flex-column ps-2">
						<span
							style={{
								lineHeight: 1.3,
								color: "",
								fontWeight: "600",
								fontSize: 16,
							}}
						>
							{track.title}
						</span>
						<span style={{ lineHeight: 1.3, color: "#8a8a8a", fontSize: 14 }}>
							{track.description}
						</span>
					</div>
				</div>
				<i
					style={{
						color: "gold",
						display: track.isPremium ? "block" : "none",
					}}
					className="bi bi-star pe-3"
				/>
			</div>
		</ListGroup.Item>
	);
};

function AudioTracks() {
	const { tracks, currentTrack, selectTrack } = useAudio();

	return (
		<ListGroup className="mb-3">
			<TrackHeader />
			{tracks.map((track) => (
				<AudioTrack
					key={track.id}
					track={track}
					currentTrack={currentTrack}
					selectTrack={selectTrack}
				/>
			))}
		</ListGroup>
	);
}

export default AudioTracks;
