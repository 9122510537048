import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./pages/ErrorPage";

import * as Sentry from "@sentry/react";
import SignIn from "./pages/logIn/SignIn";
import SignUp from "./pages/logIn/SignUp";
import ForgotPassword from "./pages/logIn/ForgotPassword";
import ResetPassword from "./pages/logIn/ResetPassword";
import VerifyEmail from "./pages/logIn/VerifyEmail";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import Home from "./pages/home/Home";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Premium } from "./pages/premium/Premium";
import { Research } from "./pages/Research";
import { Terms } from "./pages/Terms";

// Wrap the router in sentry's error handler
const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "about",
				element: <About />,
			},
			/**
			 * Blog is intentionally left out of the router because the react router does not support
			 * direct routing to outside links from the router.
			 */
			{
				path: "contact",
				element: <Contact />,
			},
			{
				path: "premium",
				element: <Premium />,
			},
			{
				path: "research",
				element: <Research />,
			},
			{
				path: "terms",
				element: <Terms />,
			},
		],
	},
	// the reason this is not nested under the main app is because the sign in page is a standalone page
	// we don't want the next components to be rendered in the main app layout
	{
		path: "/sign-in",
		element: <SignIn />,
	},
	{
		path: "/sign-up",
		element: <SignUp />,
	},
	{
		path: "/forgot-password",
		element: <ForgotPassword />,
	},
	{
		path: "/reset-password",
		element: <ResetPassword />,
	},
	{
		path: "/verify-email",
		element: <VerifyEmail />,
	},
	{
		path: "/checkout-success",
		element: <CheckoutSuccess />,
	},
]);

export default Router;
