import Card from "react-bootstrap/esm/Card";
import eureka from "/eureka.png";

export const Research = () => {
	return (
		<Card>
			<Card.Header>
				<Card.Title>Research</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Text>
					<img className="mx-auto d-block" src={eureka} alt="Eureka!" />
					<div className="pt-4 pb-3">
						<p>
							Everybody says their product is proven by research, right?
							Well...ours actually is.
						</p>
						<p>
							According to a peer-reviewed study out of the University of
							Chicago, "A moderate level of ambient noise is conducive to
							creative cognition." In a nutshell, this means being a tiny bit
							distracted helps you be more creative. This is why those AHA
							moments happen when we're brushing our teeth, taking a shower, or
							mowing the lawn! If we're not focused too much at a task at hand,
							we come up with awesome stuff. In the coffee shop, the chatter and
							clatter actually distracts us a tiny bit and allows our creative
							juices to start flowing. It sounds crazy, but it works!
						</p>
					</div>
					<a
						className="btn btn-primary mx-auto d-block"
						href="http://www.jstor.org/stable/10.1086/665048"
						target="_blank"
						rel="noreferrer"
					>
						Check out the study
					</a>
				</Card.Text>
			</Card.Body>
		</Card>
	);
};
