import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import "./signIn.css"; // Assume you have this file for custom styles
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "../../services/auth";
import Spinner from "react-bootstrap/esm/Spinner";
import Alert from "react-bootstrap/esm/Alert";

const VerifyEmail: React.FC = () => {
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const verifyToken = async () => {
			try {
				const token = searchParams.get("token");

				if (!token) {
					setError(
						"Invalid: No token found. If you're using Internet Explorer Coffitivity is no longer compatible with that browser.",
					);
					return;
				}

				const [success, error] = await authService.verifyToken(token);

				if (error) {
					setError(error.message);
					return;
				}

				if (success) {
					navigate("/sign-in?verified=true");
				}
			} catch (err) {
				console.error("Error:", err);
				setError("There was an error while processing your request.");
			}
		};

		verifyToken();
	});

	return (
		<Container fluid className="h-100">
			<Row className="justify-content-center align-items-center h-100">
				<Col xs={12} md={6} xl={4}>
					{error ? (
						<Alert variant="danger" role="alert">
							{error}
						</Alert>
					) : (
						<Spinner animation="border" variant="primary" />
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default VerifyEmail;
