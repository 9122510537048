import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import "./signIn.css"; // Assume you have this file for custom styles
import letterMark from "/letter-mark.png";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import authService from "../../services/auth";
import Alert from "react-bootstrap/esm/Alert";

const ResetPassword: React.FC = () => {
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (password !== passwordConfirmation) {
			setPasswordError("Passwords do not match");
		} else {
			setPasswordError("");
		}
	}, [password, passwordConfirmation]);

	// handle form submit
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			setError(""); // clear any previous errors
			event.preventDefault(); // prevent the form from refreshing the page

			const token = searchParams.get("token");

			if (!token) {
				setError(
					"Invalid: No token found. If you're using Internet Explorer Coffitivity is no longer compatible with that browser.",
				);
				return;
			}

			if (!password) {
				setError("Please enter a password.");
				return;
			}

			if (!passwordConfirmation) {
				setError("Please confirm your password.");
				return;
			}

			// make a post request to your server
			const [success, error] = await authService.resetPassword(password, token);

			if (error) {
				setError(error.message);
				return;
			}

			// if successful, save the tokens, redirect to the home page
			if (success) {
				setSuccess("Your password was successfully reset.");
				setPassword("");
				setPasswordConfirmation("");
			}
		} catch (err) {
			// TODO: log to sentry
			console.error("Error:", err);
			setError("We apologize, but an error occurred. Please try again later.");
		}
	};

	const SignInNavigation = () => (
		<div>
			<p>Click below to log into your account.</p>
			<Link to="/sign-in" className="btn btn-primary">
				Go To Sign In
			</Link>
		</div>
	);

	return (
		<Container fluid className="h-100">
			<Row className="justify-content-center align-items-center h-100">
				<Col xs={12} md={6} xl={4}>
					<Link to="/">
						<img
							src={letterMark}
							alt="Coffitivity"
							className="d-block mx-auto mb-3"
						/>
					</Link>

					{error && (
						<Alert variant="danger" role="alert">
							{error}
						</Alert>
					)}
					{success && (
						<Alert variant="success" role="alert">
							{success}
						</Alert>
					)}

					<Card>
						<Card.Header>
							<Card.Title className="d-flex justify-content-between">
								<span>Reset Password</span>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							{success ? (
								<SignInNavigation />
							) : (
								<Form onSubmit={handleSubmit}>
									<p className="text-body-secondary">
										Enter a new password for your account.
									</p>
									<Form.Group className="mb-3" controlId="password">
										<Form.Label>Password</Form.Label>
										<Form.Control
											type="password"
											placeholder="Password"
											onChange={(e) => setPassword(e.target.value)}
											value={password}
										/>
									</Form.Group>

									<Form.Group className="mb-3" controlId="passwordConfirmation">
										<Form.Label>Password Confirmation</Form.Label>
										<Form.Control
											type="password"
											placeholder="Password"
											isInvalid={!!passwordError}
											onChange={(e) => setPasswordConfirmation(e.target.value)}
											value={passwordConfirmation}
										/>
									</Form.Group>

									{passwordError && (
										<Form.Text className="text-danger">
											{passwordError}
										</Form.Text>
									)}

									<Row className="justify-content-end mt-3">
										<Col xs="auto">
											<Button variant="primary" type="submit">
												Reset
											</Button>
										</Col>
									</Row>
								</Form>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ResetPassword;
