import type React from "react";
import AudioElement from "./AudioElement";
import AudioControls from "./AudioControls";
import AudioTracks from "./AudioTracks";

const AudioPlayer: React.FC = () => (
	<>
		<AudioElement controls={false} />
		<AudioControls />
		<AudioTracks />
	</>
);

export default AudioPlayer;
