import { Link } from "react-router-dom"

export const TermsOfService = ({ className }: { className?: string }) => {
	const classes = `${className} mb-3`;
	
	return (
		<div className={classes}>
			<Link className="d-block text-center text-secondary-emphasis" to="/terms">Terms of Service</Link>
		</div>
	)
}