import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import coffeeCup from "/coffee-cup.png";
import letterMark from "/letter-mark.png";
import { useAuth } from "../providers/AuthProvider";
import Button from "react-bootstrap/esm/Button";

const MainNav = () => {
	const navigate = useNavigate();
	const { isAuthenticated, user, logout } = useAuth();
	const blogLink = "https://blog.coffitivity.com";
	const [expanded, setExpanded] = useState(false);

	// this order determines the layout of the navbar
	const mainNavLinks = [
		{
			link: "premium",
			icon: "bi bi-star-fill",
		},
		{
			link: "about",
			icon: "bi bi-cup-fill",
		},
		{
			link: "research",
			icon: "bi bi-lightning-fill",
		},
		{
			link: "contact",
			icon: "bi bi-chat-left-dots-fill",
		},
		{
			link: "blog",
			icon: "bi bi-eye-fill",
		},
	];

	const UserNameDisplay = () => {
		return (
			<div className="d-flex pe-2">
				<span>
					<i className="bi bi-person-fill pe-2" />
					{user.email}
				</span>
			</div>
		);
	};

	const renderLinks = () => {
		return mainNavLinks.map((navLink) => {
			return (
				<NavLink
					key={navLink.link}
					to={navLink.link === "blog" ? blogLink : navLink.link}
					onClick={() => setExpanded(false)}
					className="nav-link pe-3"
				>
					<i className={`${navLink.icon} pe-1`} />
					{navLink.link.charAt(0).toUpperCase() + navLink.link.slice(1)}
				</NavLink>
			);
		});
	};

	const handleAuthClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		if (isAuthenticated) {
			logout();
		} else {
			navigate("/sign-in");
		}
	};

	return (
		<Navbar expand="lg" className="bg-body-tertiary mb-3" expanded={expanded}>
			<div
				style={{ width: "100%", maxWidth: "1200px" }}
				className="mx-auto d-flex flex-row flex-wrap px-3 px-xxl-0"
			>
				<Navbar.Brand className="order-1 flex-grow-1 flex-lg-grow-0">
					<Link to="/" onClick={() => setExpanded(false)}>
						<img
							src={coffeeCup}
							alt="Coffitivity Icon"
							style={{ borderRadius: "6px", marginRight: "6px" }}
						/>
						<img src={letterMark} alt="Coffitivity" />
					</Link>
				</Navbar.Brand>
				<Navbar.Collapse id="navbarScroll" className="order-3">
					<Nav className="me-auto my-2 my-lg-0 ms-lg-5">{renderLinks()}</Nav>

					{isAuthenticated ? <UserNameDisplay /> : <></>}

					<Button
						variant={isAuthenticated ? "outline-primary" : "primary"}
						onClick={(e) => handleAuthClick(e)}
					>
						{isAuthenticated ? "Log Out" : "Log In"}
					</Button>
				</Navbar.Collapse>
				<Navbar.Toggle
					className="order-2"
					aria-controls="navbarScroll"
					onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
				/>
			</div>
		</Navbar>
	);
};

export default MainNav;
