import Card from "react-bootstrap/esm/Card";
import texasTeahouse from "/texas-teahouse.jpg";
import brazilBistro from "/brazil-bistro.jpg";
import parisParadise from "/paris-paradise.jpg";
import { useAuth } from "../../providers/AuthProvider";
import "./premium.css";
import Button from "react-bootstrap/esm/Button";
import payment from "../../services/payment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/esm/Alert";
import { useEffect, useState } from "react";

export const Premium = () => {
	const navigate = useNavigate();
	const { isAuthenticated, user } = useAuth();
	const [error, setError] = useState("");
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const errorParam = searchParams.get("error");
		if (errorParam) {
			setError(
				"There was an issue processing your transaction. Someone from our team will be in contact shortly.",
			);
		}
	});

	const handlePaymentClick = async () => {
		if (isAuthenticated) {
			const [data] = await payment.createCheckoutSession({
				priceId:
					import.meta.env.VITE_ENV === "production"
						? "price_1QFmPyKub8JG5F4wAebs5ej1"
						: "price_1Q9UrVKub8JG5F4wHjmBxIVL",
				email: user.email,
			});
			if (data) {
				window.location.href = data.sessionUrl;
			}
		} else {
			navigate("/sign-up");
		}
	};

	const DefaultPremiumView = () => (
		<Card>
			<Card.Header>
				<Card.Title>Get Premium Today - Only $9!</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Text>
					<div className="premium-cards-container">
						<div className="premium-card">
							<span className="premium-card-title">Paris Paradise</span>
							<img
								className="premium-card-image"
								src={parisParadise}
								alt="Paris Paradise"
							/>
							<span className="premium-card-icon">
								<i className="bi bi-star" />
							</span>
						</div>
						<div className="premium-card">
							<span className="premium-card-title">Brazil Bistro</span>
							<img
								className="premium-card-image"
								src={brazilBistro}
								alt="Brazil Bistro"
							/>
							<span className="premium-card-icon">
								<i className="bi bi-star" />
							</span>
						</div>
						<div className="premium-card">
							<span className="premium-card-title">Texas Teahouse</span>
							<img
								className="premium-card-image"
								src={texasTeahouse}
								alt="Texas Teahouse"
							/>
							<span className="premium-card-icon">
								<i className="bi bi-star" />
							</span>
						</div>
					</div>
					<div className="pt-4 pb-3">
						<p>
							More Sounds When You Need Them - As a Premium guest you'll get
							unlimited access to our audio tracks to perfect your workday.
						</p>
						<p>
							For only $9 you'll get 1 year of the best boost for your buck. All
							without even having to glance up from your device!
						</p>
					</div>
					{error && (
						<Alert variant="danger" role="alert">
							{error}
						</Alert>
					)}
					<Button
						variant="primary"
						className="mx-auto d-block w-100"
						onClick={async () => await handlePaymentClick()}
					>
						{isAuthenticated ? "Buy Now" : "Login to Upgrade"}
					</Button>
				</Card.Text>
			</Card.Body>
		</Card>
	);

	const SubscribedView = () => {
		const getEndDateSentence = () => {
			if (user.subscription?.end_date) {
				const endDate = new Date(user.subscription.end_date).toDateString();
				return `Your subscription will end on ${endDate}.`;
			}

			// TODO: put a sentry call here
			return "There's an error with your account. Please contact support.";
		};

		return (
			<Card>
				<Card.Header>
					<Card.Title>Thanks for subscribing to premium!</Card.Title>
				</Card.Header>
				<Card.Body>
					<Card.Text>
						<h3>{getEndDateSentence()}</h3>
						<p>
							This account does not auto renew and it will expire on the end
							date.
						</p>
					</Card.Text>
				</Card.Body>
			</Card>
		);
	};

	return isAuthenticated && user?.is_subscribed ? (
		<SubscribedView />
	) : (
		<DefaultPremiumView />
	);
};
