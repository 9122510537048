import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/esm/Card";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import "./signIn.css"; // Assume you have this file for custom styles
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import letterMark from "/letter-mark.png";
import type { TooltipProps } from "react-bootstrap/esm/Tooltip";
import Tooltip from "react-bootstrap/esm/Tooltip";
import Alert from "react-bootstrap/esm/Alert";
import { useAuth } from "../../providers/AuthProvider";

const SignIn: React.FC = () => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [alert, setAlert] = useState("");
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const verified = searchParams.get("verified");
		if (verified) {
			setAlert("Your account has been verified, please log in.");
		}
	}, [searchParams]);

	// handle form submit
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		setError(""); // clear any previous errors
		event.preventDefault(); // prevent the form from refreshing the page

		try {
			if (!email) {
				setError("Please enter an email address.");
				return;
			}

			if (!password) {
				setError("Please enter a password.");
				return;
			}

			await login(email, password);
			navigate("/");
		} catch (err) {
			setError(
				err instanceof Error
					? err.message
					: "We apologize, but an error occurred. Please try again later.",
			);
		}
	};

	const renderTooltip = (
		props: JSX.IntrinsicAttributes &
			TooltipProps &
			React.RefAttributes<HTMLDivElement>,
	) => (
		<Tooltip id="button-tooltip" {...props}>
			You can log into your account by resetting your password using the email
			address associated with your Facebook account.
		</Tooltip>
	);

	return (
		<Container fluid className="h-100">
			<Row className="justify-content-center align-items-center h-100">
				<Col xs={12} md={6} xl={4}>
					<Link to="/">
						<img
							src={letterMark}
							alt="Coffitivity"
							className="d-block mx-auto mb-3"
						/>
					</Link>
					{error && <Alert variant="danger">{error}</Alert>}
					{alert && (
						<Alert variant="success" role="alert">
							{alert}
						</Alert>
					)}
					<Card>
						<Card.Header>
							<Card.Title className="d-flex justify-content-between">
								<span>Sign In</span>
								<Link to="/sign-up" className="btn btn-link">
									Create Account
								</Link>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="signInEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</Form.Group>

								<Form.Group controlId="signInPassword">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
									/>
								</Form.Group>

								<div className="d-flex flex-row-reverse justify-content-between mt-3">
									<Button variant="primary" type="submit">
										Sign In
									</Button>
									<Button variant="warning" onClick={() => navigate(-1)}>
										Back
									</Button>
								</div>

								<div className="d-flex justify-content-between mt-3">
									<OverlayTrigger
										placement="top"
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip}
									>
										<Button variant="link">Facebook Login?</Button>
									</OverlayTrigger>
									<Link to="/forgot-password" className="btn btn-link">
										Forgot Password?
									</Link>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default SignIn;
