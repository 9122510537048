import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider } from "react-router-dom";
import Router from "./Router.tsx";
import AuthProvider from "./providers/AuthProvider.tsx";
import AudioProvider from "./providers/AudioProvider.tsx";
import "./index.css";
import "./ErrorHandler.ts";
import { ErrorBoundary } from "./components/ErrorBoundary.tsx";
import ErrorPage from "./pages/ErrorPage.tsx";
import ReactGA from "react-ga4";

const Analytics: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	useEffect(() => {
		const env = import.meta.env.VITE_ENV;

		// TODO: build a dictionary for this value
		if (env === "production") {
			ReactGA.initialize("G-P2S6LKFT32");
		}
	}, []);

	// use this class to center the auth pages
	return <div className="h-100">{children}</div>;
};

const rootElement = document.getElementById("root");
if (rootElement) {
	createRoot(rootElement).render(
		<StrictMode>
			<ErrorBoundary fallback={<ErrorPage />}>
				<Analytics>
					<AuthProvider>
						<AudioProvider>
							<RouterProvider router={Router} />
						</AudioProvider>
					</AuthProvider>
				</Analytics>
			</ErrorBoundary>
		</StrictMode>,
	);
}
